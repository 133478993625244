// split text
// https://codepen.io/plankton/pen/WNgYGWg

// Inspired by the YouTube series by Adam Argyle https://www.youtube.com/watch?v=3hvN7bkjZBk
// and https://codepen.io/GreenSock/pen/pEKYVz

// Work in progress
export default class Divido {
    constructor(t, e = {}) {
        (this.motionOK = window.matchMedia(
            "(prefers-reduced-motion: no-preference)"
        ).matches),
            (this.selector = t),
            (this.options = e),
            this.splitTextContent();
    }
    get char() {
        if (this.selector)
            return this.selector.querySelectorAll('.char');
        return document.querySelector('[data-split="char"]') ? ".char" : null;
    }
    get word() {
        if (this.selector)
            return this.selector.querySelectorAll('.word');
        return document.querySelector('[data-split="word"]') ? ".word" : null;
    }
    createSpanNode(t, e, r) {
        const n = document.createElement("span");
        return (n.textContent = t), n.style.setProperty(`--${r}-index`, e), n;
    }
    splitByChar(t, e = 0) {
        let r = [...t].map((t, r) => this.createSpanNode(t, r + e, "char"));
        return (
            r.forEach((t) => {
                t.classList.add("char");
            }),
                r
        );
    }
    splitWordsRecursively(t, e = 0, r = null) {
        return (
            Array.from(t.childNodes).forEach((n) => {
                if (n.nodeType === Node.TEXT_NODE) {
                    const r = n.textContent.trim().split(" "),
                        o = r.filter((t) => "" !== t),
                        s = [];
                    for (let n = 0; n < o.length; n++) {
                        const i = o[n],
                            c = this.createSpanNode(i, e, "word");
                        c.classList.add("word");
                        const l = document.createElement("span");
                        if (
                            (l.classList.add("wrap"),
                                l.setAttribute("aria-hidden", !0),
                                l.appendChild(c),
                                s.push(l.outerHTML),
                                !(
                                    n === o.length - 1 ||
                                    (r[n + 1][0].match(/[.,!]/) && t.childNodes[n + 1].tagName)
                                ))
                        ) {
                            const t = document.createElement("span");
                            (t.textContent = " "),
                                t.classList.add("whitespace"),
                                s.push(t.outerHTML);
                        }
                        e++;
                    }
                    const i = document.createElement("div");
                    (i.innerHTML = s.join("")),
                        Array.from(i.childNodes).forEach((e) => {
                            t.insertBefore(e, n);
                        }),
                        t.removeChild(n);
                } else if (n.nodeType === Node.ELEMENT_NODE) {
                    if (null !== r && r.nodeType === Node.TEXT_NODE) {
                        if (r.textContent.trim().length > 0) {
                            const e = document.createElement("span");
                            (e.textContent = " "),
                                e.classList.add("whitespace"),
                                t.insertBefore(e, n);
                        }
                    }
                    if (((e = this.splitWordsRecursively(n, e, n)), n.nextSibling)) {
                        const e = n.nextSibling,
                            r = e.textContent.trim();
                        if (
                            e.nodeType === Node.TEXT_NODE &&
                            r.length > 0 &&
                            r[0].match(/[.,!]/) &&
                            n.tagName
                        ) {
                            const e = t.querySelector(".whitespace:last-child");
                            e && t.removeChild(e);
                        } else if (
                            r.length > 0 &&
                            (!r[0].match(/[.,!]/) || !n.nextSibling.tagName)
                        ) {
                            const r = document.createElement("span");
                            (r.textContent = " "),
                                r.classList.add("whitespace"),
                                t.insertBefore(r, e);
                        }
                    }
                }
                r = n;
            }),
                e
        );
    }
    splitTextContent() {
        if (!this.motionOK) return;
        const t = this.selector;
        let e = 0;
        const r = this.options.type || t.getAttribute('data-split'),
            n = t.textContent.trim();
        if (
            (t.setAttribute('aria-label', n), 'char' === r || 'word, char' === r)
        ) {
            this.splitWordsRecursively(t);
            t.querySelectorAll('.word').forEach((t) => {
                const n = this.splitByChar(t.textContent, e),
                    o = t.textContent.length;
                (t.textContent = ''),
                    'char' === r
                        ? t.replaceWith(...n)
                        : 'word, char' === r && n.forEach((e) => t.appendChild(e)),
                    (e += o);
            });
        } else 'word' === r && this.splitWordsRecursively(t);
    }
};
