export default class Intersect {
    isSafari = false;
    scrollObserverOptions = {};
    scrollObserver = null;
    elements = {};

    constructor(params = {}) {
        this.scrollObserverOptions.root = null;
        this.scrollObserverOptions.rootMargin = params.rootMargin || '0px 0px -5% 0px';
        this.scrollObserverOptions.thresholds = params.thresholds || [0.01, 0.25, 0.5, 0.99];

        const ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                // Chrome
            } else {
                this.isSafari = true; // Safari
                this.scrollObserverOptions.root = null;
            }
        }
        if (ua.indexOf('edge') > -1) {
            this.scrollObserverOptions.root = null;
        }

        // console.log(this.scrollObserverOptions);
        this.scrollObserver = new IntersectionObserver((elements) => {
            elements.forEach((element) => {
                if (!element.target) {
                    return true;
                }
                // console.log(element.intersectionRatio, element.isIntersecting, element.target);
                if (element.intersectionRatio > 0) {
                    element.target.classList.add('view');
                    this.callback(element.target.dataset.ouuid, true);
                } else {
                    element.target.classList.remove('view');
                    this.callback(element.target.dataset.ouuid, false);
                }
                return true;
            });
        }, this.scrollObserverOptions);
    }

    add = (el, binding) => {
        if (el) {
            // eslint-disable-next-line no-param-reassign
            el.dataset.ouuid = this.uuidv4();
            if (binding) {
                // console.log('binding gefunden');
                this.elements[el.dataset.ouuid] = binding.value;
            }
            // console.log('observe', el);
            this.scrollObserver.observe(el);
        }
    }

    remove = (el) => {
        if (el) {
            // console.log('unobserve', el);
            this.scrollObserver.unobserve(el);
        }
    }

    uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });

    callback = (ouuid, intersects) => {
        // console.log('callback', intersects, ouuid, this.elements[ouuid]);
        if (ouuid && ouuid in this.elements) {
            if ('onChange' in this.elements[ouuid]) {
                this.elements[ouuid].onChange(intersects);
            }
        }
    }
}
