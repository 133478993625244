import Lenis from '@studio-freight/lenis';
import Divido from './divido';
import SwInteractiveReveal from './swInteractiveReveal';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import axios from 'axios';
import Swiper from 'swiper';

gsap.registerPlugin(ScrollTrigger);

class SWScroll {
    constructor() {
        this.easing = {
            // no easing, no acceleration
            linear: t => t,
            // accelerating from zero velocity
            easeInQuad: t => t * t,
            // decelerating to zero velocity
            easeOutQuad: t => t * (2 - t),
            // acceleration until halfway, then deceleration
            easeInOutQuad: t => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
            // accelerating from zero velocity
            easeInCubic: t => t * t * t,
            // decelerating to zero velocity
            easeOutCubic: t => (--t) * t * t + 1,
            // acceleration until halfway, then deceleration
            easeInOutCubic: t => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
            // accelerating from zero velocity
            easeInQuart: t => t * t * t * t,
            // decelerating to zero velocity
            easeOutQuart: t => 1 - (--t) * t * t * t,
            // acceleration until halfway, then deceleration
            easeInOutQuart: t => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t,
            // accelerating from zero velocity
            easeInQuint: t => t * t * t * t * t,
            // decelerating to zero velocity
            easeOutQuint: t => 1 + (--t) * t * t * t * t,
            // acceleration until halfway, then deceleration
            easeInOutQuint: t => t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t,
            // elastic bounce effect at the beginning
            easeInElastic: t => (.04 - .04 / t) * Math.sin(25 * t) + 1,
            // elastic bounce effect at the end
            easeOutElastic: t => .04 * t / (--t) * Math.sin(25 * t),
            // elastic bounce effect at the beginning and end
            easeInOutElastic: t => (t -= .5) < 0 ? (.02 + .01 / t) * Math.sin(50 * t) : (.02 - .01 / t) * Math.sin(50 * t) + 1
        };
        console.log('%cswScroll', 'background: #222; color: #bada55');
        this.isSafari = false;
        this.elements = {};
        this.parallaxElements = [];
        this.scrollZoomElements = [];
        this.scrollMirrorElements = [];
        this.sidescrollElements = [];
        this.scrollTriggerElements = [];
        this.scrollFunctions = [];
        this.mouseParallaxElements = [];
        this.mouseMoveFunctions = [];
        this.cursorElements = {};
        this.winHeight = -1;
        this.winWidth = -1;
        this.mouseX = -1;
        this.mouseY = -1;
        this.mouseXPercent = 0.5;
        this.mouseYPercent = 0.5;
        this.scrollObserverOptions = {
            root: document,
            rootMargin: '0px 0px -5% 0px',
            thresholds: [0.1, 0.25, 0.5, 1]
        };
        this.modal = {
            open: false,
            dom: null,
            window: null,
            close: null,
            layouts: {}
        };
        // safari
        const ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') != -1) {
            if (ua.indexOf('chrome') > -1) {
                // Chrome
            } else {
                this.isSafari = true; // Safari
                this.scrollObserverOptions.root = null;
            }
        }
        if (ua.indexOf('edge') > -1) {
            this.scrollObserverOptions.root = null;
        }
        // initialize
        this.initMouse();
        this.initResize();
        this.initScroll();
        this.initObserver();
        this.initClick();
        this.initModal();
    }

    initMouse() {
        window.addEventListener('mousemove', function (e) {
            this.mouseMove(e);
        }.bind(this));
        [...document.querySelectorAll('[data-mouseparallax]')].forEach((el) => {
            const element = {
                dom: el,
                params: el.dataset.mouseparallax,
                distance: 40,
                speed: [0, 0],
                current: [0, 0],
                target: [0, 0]
            };
            if (element.params.indexOf(';') > -1) {
                const params = element.params.split(';');
                element.speed[0] = parseFloat(params[0]);
                element.speed[1] = parseFloat(params[1]);
            } else {
                element.speed[0] = element.speed[1] = parseFloat(element.params);
            }
            this.mouseParallaxElements.push(element);
        });
        const mouseParallaxLoop = function () {
            this.mouseParallaxElements.forEach((el) => {
                if (el.current[0] !== el.target[0] || el.current[1] !== el.target[1]) {
                    el.current[0] = this.lerp(el.current[0], el.target[0], 0.1).toFixed(2);
                    el.current[1] = this.lerp(el.current[1], el.target[1], 0.1).toFixed(2);
                    el.dom.style.transform = 'translate(' + el.current[0] + 'px, ' + el.current[1] + 'px)';
                }
            });
            requestAnimationFrame(mouseParallaxLoop);
        }.bind(this);
        if (this.mouseParallaxElements.length) {
            requestAnimationFrame(mouseParallaxLoop);
        }
    }

    initResize() {
        window.addEventListener('resize', function () {
            this.resize();
        }.bind(this));
        this.resize();
    }

    initObserver() {
        // create observer
        this.scrollObserver = new IntersectionObserver(function (elements) {
            this.observe(elements);
        }.bind(this), this.scrollObserverOptions);
        // query existing images
        [...document.querySelectorAll('[data-viewjs]')].forEach((el) => {
            if (!el.dataset.fadeid) {
                el.dataset.fadeid = this.uuidv4();
                this.addScrollObserver(el);
            }
        });
    }

    initScroll() {
        this.scrollTop = -1;
        // scroll event listener
        // window.addEventListener('scroll', function (e) {
        //     this.scroll();
        // }.bind(this));
        this.lenis = new Lenis({
            lerp: 0.1,
            smooth: true
        });

        // add data-parallax elements
        [...document.querySelectorAll('[data-parallax]')].forEach(el => {
            this.addParallaxElement(el);
        });
        // add data-scrollfit elements
        [...document.querySelectorAll('[data-scrollfit]')].forEach(el => {
            this.addScrollfitElement(el);
        });
        // add data-scroll and data-scroll-function elements
        [...document.querySelectorAll('[data-scroll],[data-scrolljs]')].forEach(el => {
            this.addScrollTriggerElement(el);
        });
        // this.scrollTop = 0;
        // this.scroll();

        //get scroll value
        this.lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
            // console.log({ scroll, limit, velocity, direction, progress })
            // on scroll set var and call all registered functions
            if (scroll !== this.scrollTop) {
                // console.log('lenis scroll');
                // console.time('parallax');
                this.scrollTop = scroll;
                this.scroll();
                // console.timeEnd('parallax');
            }
        });
        const raf = (time) => {
            this.lenis.raf(time);
            requestAnimationFrame(raf);
        };
        requestAnimationFrame(raf);

        this.scrollTop = window.scrollY || document.body.scrollTop;
        this.scroll();
    }

    initClick() {
        [...document.querySelectorAll('[data-click],[data-clickjs]')].forEach(el => {
            el.addEventListener('click', function (e) {
                e.preventDefault();
                if (el.dataset.click) {
                    el.classList.add(el.dataset.click);
                }
                if (el.dataset.clickjs) {
                    eval(el.dataset.clickjs);
                }
            }.bind(this));
        });
    }

    mouseMove(e) {
        if (e.clientX !== this.mouseX || e.clientY !== this.mouseY) {
            this.mouseX = e.clientX;
            this.mouseY = e.clientY;
            if (this.winWidth > -1 && this.winHeight > -1) {
                this.mouseXPercent = this.mouseX / this.winWidth - 0.5;
                this.mouseYPercent = this.mouseY / this.winHeight - 0.5;
            }
            this.mouseParallaxElements.forEach(el => {
                el.target[0] = (el.speed[0] * el.distance * this.mouseXPercent).toFixed(2);
                el.target[1] = (el.speed[1] * el.distance * this.mouseYPercent).toFixed(2);
            });
            this.mouseMoveFunctions.forEach(fn => fn());
        }
    }

    /*
    called by window event 'scroll' and after resize
    */
    scroll(force = false) {
        this.scrollFunctions.forEach(fn => fn());
        this.parallax();
        this.sidescroll();
        this.scrollTrigger();
        this.scrollZoom();
        this.scrollMirror();
        // const scrollY = window.scrollY || document.body.scrollTop;
        // // on scroll set var and call all registered functions
        // if (scrollY !== this.scrollTop || force) {
        //     // console.log('scroll', force);
        //     console.log('native scroll');
        //     this.scrollTop = scrollY;
        //     this.scrollFunctions.forEach(fn => fn());
        //     this.parallax();
        //     this.sidescroll();
        //     this.scrollTrigger();
        // }
    }

    resize() {
        const winWidth = window.innerWidth;
        const winHeight = window.innerHeight;
        if (winWidth !== this.winWidth || winHeight !== this.winHeight) {
            this.winWidth = winWidth;
            this.winHeight = winHeight;
            this.scroll();
        }
    }

    // function called by observer
    observe(elements) {
        // loop over elements, if intersecting we preload
        elements.forEach(function (element) {
            if (element.isIntersecting) {
                this.intersect(element);
            } else {
                this.unintersect(element);
            }
        }.bind(this));
    }

    intersect(element) {
        setTimeout(() => {
            element.target.classList.add('view');
            if (element.target.dataset.view) {
                element.target.classList.add(element.target.dataset.view);
            }
            if (element.target.dataset.viewjs) {
                try {
                    eval(element.target.dataset.viewjs);
                } catch (e) {
                    console.log(e);
                }
            }
        }, 30);
    }

    unintersect(element) {
        // element.target.classList.remove('view');
        if (element.target.dataset.view) {
            element.target.classList.remove(element.target.dataset.view);
        }
        if (element.target.dataset.viewjsOut) {
            try {
                eval(element.target.dataset.viewjsOut);
            } catch (e) {
                console.log(e);
            }
        }
    }

    /* generate UUID */
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    lerp(min, max, fraction) {
        return (parseFloat(max) - parseFloat(min)) * fraction + parseFloat(min);
    }

    // add observer element
    addScrollObserver(element, animation = null) {
        const t = this;
        const el = {
            element: element,
            animation: animation,
            headline: element.dataset.viewjs && element.dataset.viewjs.indexOf('Headline') > -1 ? () => {
                // this.fadeInHeadline({target: element});
            } : null
        };
        this.elements[element.dataset.fadeid] = el;
        this.scrollObserver.observe(element);
    }

    removeScrollObserver(element) {
        this.scrollObserver.unobserve(element);
    }

    addOnScroll(fn) {
        this.scrollFunctions.push(fn);
    }

    getParentBySelector(el, selector) {
        let parent = el.parentElement;
        let foundParent = false;
        while (!foundParent && parent) {
            if (parent.classList.contains(selector)) {
                foundParent = true;
            } else {
                if (parent.type !== 'HTML') {
                    // get to the next parent element
                    parent = parent.parentElement;
                } else {
                    // found no parent with the selector, we use the first parent
                    parent = el.parentElement;
                    foundParent = true;
                }
            }
        }
        if (!parent) {
            parent = document.querySelector('main');
        }
        return parent;
    }

    addScrollfitElement(el) {
        if (el.dataset.scrollfituuid) {
            return;
        }
        el.dataset.scrollfituuid = this.uuidv4();
        // find closest parent with .scrollfit__parent
        const parent = el.closest('.scrollfit__parent') || el.parentElement;
        el.classList.add('will-transform');
        const data = {
            el: el,
            parent: parent,
            bounding: parent.getBoundingClientRect(),
            boundingSelf: el.getBoundingClientRect(),
            directParent: el.parentElement,
            intersecting: false,
            onlyTop: false,
            speed: 1,
            mobile: true,
            scrollfit: true
        };
        this.parallaxElements.push(data);
    }

    addParallaxElement(el) {
        if (el.dataset.parallaxuuid) {
            return;
        }
        el.dataset.parallaxuuid = this.uuidv4();
        let parent = el.parentElement;
        if (el.dataset.parallaxParent) {
            parent = this.getParentBySelector(el, el.dataset.parallaxParent);
        }
        el.classList.add('will-transform');
        this.parallaxElements.push({
            el: el,
            parent: parent,
            bounding: parent.getBoundingClientRect(),
            onlyTop: el.dataset.parallaxTop || false,
            speed: parseFloat(el.dataset.parallax),
            setOpacity: el.dataset.parallaxOpacity || false,
            speedMobile: el.dataset.parallaxMobile ? parseFloat(el.dataset.parallaxMobile) : parseFloat(el.dataset.parallax),
            mobile: el.dataset.parallaxMobile ? el.dataset.parallaxMobile : false || false
        });
    }

    addScrollZoomElement(el) {
        if (el.dataset.scrollzoomuuid) {
            return;
        }
        el.dataset.scrollzoomuuid = this.uuidv4();
        const child = el.querySelector('.sticky');
        const bg = child.querySelector('.scroll-zoom__background');
        const image = bg.querySelector('img');
        this.scrollZoomElements.push({
            parent: el,
            el: child,
            image: image,
            bounding: el.getBoundingClientRect(),
            start: bg.dataset.zoomStart / 100,
            end: bg.dataset.zoomEnd / 100,
            factorMobile: 1.4,
            content: el.querySelector('.scroll-zoom__content'),
            contentVisible: false
        });
    }

    addScrollMirrorElement(el) {
        if (el.dataset.scrollmirroruuid) {
            return;
        }
        el.dataset.scrollmirroruuid = this.uuidv4();
        const child = el.querySelector('.sticky');
        const bg = child.querySelectorAll('.scroll-mirror__image');
        this.scrollMirrorElements.push({
            parent: el,
            el: child,
            container: el.querySelector('.scroll-mirror__container'),
            headline: el.querySelector('.scroll-mirror__headline'),
            text: el.querySelector('.scroll-mirror__text'),
            bg: [...bg],
            bounding: el.getBoundingClientRect(),
            content: el.querySelector('.scroll-mirror__content'),
            contentVisible: false
        });
    }

    isInViewport(bounding) {
        return bounding.top <= this.winHeight * 1.1 && bounding.top + bounding.height > -50;
    }

    // scroll zoom elements
    scrollZoom() {
        this.scrollZoomElements.forEach(item => {
            const bounding = item.parent.getBoundingClientRect();
            if (this.isInViewport(bounding)) {
                const bTop = bounding.top * -1 + this.winHeight / 2;
                const bHeight = bounding.height;
                const sectionHeight = bHeight - this.winHeight;
                let scrollProgress = bTop / sectionHeight;
                if (scrollProgress < 0) {
                    scrollProgress = 0;
                }
                if (scrollProgress > 1) {
                    scrollProgress = 1;
                }
                const startMoveAt = .4;
                let moveProgress = (scrollProgress - startMoveAt) / (1 - startMoveAt);
                if (moveProgress < 0) {
                    moveProgress = 0;
                }
                const itemStart = (this.winWidth < 768 ? item.factorMobile : 1) * item.start;
                const itemEnd = (this.winWidth < 768 ? item.factorMobile : 1) * item.end;
                const diff = itemStart - itemEnd;
                const scale = itemStart - (diff * this.easing.easeInOutCubic(scrollProgress));
                let translateX = 50 - (this.easing.easeInOutCubic(moveProgress) * 50);
                let translateY = -50;
                if (this.winWidth < 768) {
                    translateX = 50;
                    translateY = -50 + (this.easing.easeInOutCubic(moveProgress) * 50);
                }
                const rotate = 60 - 60 * this.easing.easeInOutCubic(scrollProgress);
                item.image.style.transform = `translate(${translateX}%, ${translateY}%) rotate(${rotate}deg)`;
                item.image.style.width = `${scale * 100}%`;
                if (!item.contentVisible && scrollProgress >= .9) {
                    item.contentVisible = true;
                    item.content.classList.add('view');
                }
                if (item.contentVisible && scrollProgress < .9) {
                    item.contentVisible = false;
                    item.content.classList.remove('view');
                }
            }
        });
    }

    // scroll zoom elements
    scrollMirror() {
        this.scrollMirrorElements.forEach(item => {
            const bounding = item.parent.getBoundingClientRect();
            if (this.isInViewport(bounding)) {
                const bTop = bounding.top * -1 + this.winHeight / 2;
                const bHeight = bounding.height;
                const sectionHeight = bHeight - this.winHeight / 2;
                let scrollProgress = bTop / sectionHeight;
                // console.log(bTop, scrollProgress)
                if (scrollProgress < 0) {
                    scrollProgress = 0;
                }
                if (scrollProgress > 1) {
                    scrollProgress = 1;
                }
                const endMoveAt = .60;
                const startZoomAt = 0.40;
                const endZoomAt = 0.80;
                const startBlurAt = .66;
                const endBlurAt = 0.8;

                // we calculate the scroll progress when we start to zoom to original height
                const zoomProgress = Math.min(Math.max((scrollProgress - startZoomAt) / (endZoomAt - startZoomAt), 0), 1);
                let widthTarget = this.winWidth > 1800 ? 1640 : this.winWidth - 160; // <- zoom to container width
                let heightTarget = this.winHeight - 360;
                if (this.winWidth < 768) {
                    widthTarget = this.winWidth - 60;
                    heightTarget = this.winHeight - 40 - item.headline.clientHeight * 2;
                }
                const width = this.winWidth - (this.winWidth - widthTarget) * this.easing.easeInOutQuad(zoomProgress);
                const height = this.winHeight - (this.winHeight - heightTarget) * this.easing.easeInOutCubic(zoomProgress);

                // calculate the blur progress
                // const blurProgress = Math.min(Math.max((scrollProgress - startBlurAt) / (endBlurAt - startBlurAt), 0), 1);

                // okay, so we slide the mirror until .5 scroll
                const currentSlideProgress = Math.min(scrollProgress / endMoveAt, 1);
                item.bg.forEach((bg, i) => {
                    const y = (i === 0 ? 1 : -1) * ((1 - currentSlideProgress) * 100);
                    bg.style.transform = `translateY(${y}%)`;
                    // set blur/opacity
                    // bg.style.opacity = 1 - blurProgress / 2;
                    // bg.style.filter = `blur(${blurProgress * 20}px)`;
                });

                item.container.style.width = `${width}px`;
                item.container.style.height = `${height}px`;
                item.headline.style.opacity = zoomProgress;
            }
        });
    }

    // move the item relative to the parent
    parallax() {
        this.parallaxElements.forEach(item => {
            const bounding = item.parent.getBoundingClientRect();
            // if a scrollfit element
            if (this.isInViewport(bounding)) {
                if (item.scrollfit) {
                    const boundingSelf = item.el.getBoundingClientRect();
                    const boundingParent = item.directParent.getBoundingClientRect();
                    const heightDifference = boundingSelf.height - boundingParent.height;
                    // const heightDifference = item.el.clientHeight - item.parent.clientHeight;
                    // we only need this magic, if the el is higher than it's parent
                    if (heightDifference > 0) {
                        const scrollDistance = bounding.height - this.winHeight; // the distance we need to scroll
                        // const scrollDistance = item.parent.clientHeight - this.winHeight; // the distance we need to scroll
                        // scrollprogress starts at bounding.top < viewport top, ends at bounding bottom edge at either viewport top or viewport bottom. depends on style factor, may change
                        const scrollProgress = bounding.top < 0 ? Math.min(-bounding.top / scrollDistance, 1) : 0; // the progress
                        // const scrollProgress = boundingTop < 0 ? Math.min(-boundingTop / scrollDistance, 1) : 0; // the progress
                        // now we need to translate the item.el depending on the scroll progress
                        const translate = heightDifference / 2 - (heightDifference * scrollProgress);
                        item.el.style.transform = `translate3d(0, ${translate}px, 0)`;
                    } else {
                        item.el.style.transform = 'translate3d(0, 0, 0)';
                    }
                } else {
                    let top = 0;
                    if (item.onlyTop) {
                        if (bounding.top <= 0) {
                            top = bounding.top;
                        }
                    } else {
                        top = (bounding.top + bounding.height / 2) - this.winHeight / 2;
                    }
                    // if (this.winWidth < 768 && !item.mobile) {
                    //     top = 0;
                    // }
                    item.el.style.transform = 'translate3d(0, ' + (top * (this.winWidth < 768 ? item.speedMobile : item.speed)) + 'px, 0)';
                    if (item.setOpacity) {
                        // console.log(factor);
                        item.el.style.opacity = Math.max(0, Math.min(1, 1 + (top + bounding.height - this.winHeight) / this.winHeight));
                    }
                }
            }
        });
    }

    // side scroll elements
    sidescroll() {
        this.sidescrollElements.forEach(item => {
            const bounding = item.parent.getBoundingClientRect();
            if (this.isInViewport(bounding)) {
                const bTop = bounding.top * -1;
                const bHeight = bounding.height;
                const sectionHeight = bHeight - this.winHeight;
                let scrollProgress = bTop / sectionHeight;
                if (scrollProgress < 0) {
                    scrollProgress = 0;
                }
                if (scrollProgress > 1) {
                    scrollProgress = 1;
                }
                const translate = (item.flex.scrollWidth - this.winWidth) * (scrollProgress);
                item.flex.style.transform = `translate3d(${-translate}px, 0, 0)`;
            }
        });
    }

    addScrollTriggerElement(el, fn = null, fnOut = null) {
        if (el.dataset.scrolluuid) {
            return;
        }
        el.dataset.scrolluuid = this.uuidv4();
        let parent = el;
        if (el.dataset.scrollParent) {
            parent = this.getParentBySelector(el, el.dataset.scrollParent);
        }
        this.scrollTriggerElements.push({
            el: el,
            parent: parent,
            bounding: parent.getBoundingClientRect(),
            section: this.getParentBySelector(el,'pimcore_area_content'),
            class: el.dataset.scroll || null,
            fn: fn ? fn : (el.dataset.scrolljs || null),
            fnOut: fnOut ? fnOut : (el.dataset.scrolljsout || null),
            anchor: typeof el.dataset.scrollAnchor !== 'undefined' ? parseFloat(el.dataset.scrollAnchor) : 0.5,
            once: typeof el.dataset.scrollOnce !== 'undefined' && el.dataset.scrollOnce === 'true',
            triggered: false,
            triggerCount: 0
        });
    }

    scrollTrigger() {
        this.scrollTriggerElements.forEach(item => {
            const sectionBounding = item.section.getBoundingClientRect();
            if (this.isInViewport(sectionBounding)) {
                const bounding = item.parent.getBoundingClientRect();
                const top = (bounding.top + bounding.height * item.anchor) - this.winHeight / 2;
                if (bounding.top <= 0 || top <= 0) {
                    if (!item.triggered) {
                        item.triggerCount++;
                        if (!item.once || (item.once && item.triggerCount === 1)) {
                            item.triggered = true;
                            if (item.class) {
                                item.el.classList.add(item.class);
                            }
                            if (item.fn) {
                                if (typeof item.fn === 'function') {
                                    item.fn();
                                } else {
                                    const exec = function () {
                                        eval(item.fn);
                                    }.bind(this);
                                    exec();
                                }
                            }
                        }
                    }
                } else {
                    if (item.triggered) {
                        item.triggered = false;
                        if (item.class && !item.once) {
                            item.el.classList.remove(item.class);
                        }
                        if (item.fnOut) {
                            if (typeof item.fnOut === 'function') {
                                item.fnOut();
                            } else {
                                const exec = function () {
                                    eval(item.fnOut);
                                }.bind(this);
                                exec();
                            }
                        }
                    }
                }
            }
        });
    }

    postitReveal(postit, tl, startTime, speed) {
        tl.fromTo(postit.querySelector('.postit__item__shadow'),{
            opacity: 0
        }, {
            opacity: 1,
            duration: 0.8 * speed,
            ease: 'power2.out'
        }, startTime);
        const rot3d = {
            x: 0.5,
            y: -0.6,
            z: 0.1
        };
        const paper = postit.querySelector('.postit__item__paper');
        tl.fromTo(paper, {
            opacity: 0
        }, {
            opacity: 1,
            duration: 0.3 * speed,
            ease: 'none'
        }, startTime + 0.1 * speed);
        tl.to(rot3d, {
            x: 0,
            y: 0,
            z: 0,
            onUpdate: () => {
                paper.style.transform = `rotateX(${rot3d.x}rad) rotateY(${rot3d.y}rad) rotateZ(${rot3d.z}rad)`;
            },
            duration: 1.3 * speed,
            ease: 'power3.out'
        }, startTime + 0.1 * speed);
        tl.fromTo(postit.querySelector('.postit__item__ring'), {
            opacity: 0
        }, {
            opacity: 1,
            duration: 0.5 * speed,
            ease: 'none'
        }, startTime + 0.1 * speed + 1.3 * speed);
        const text = postit.querySelector('.postit__item__text');
        const clip = {
            x: 0
        }
        tl.to(clip, {
            x: 100,
            duration: 0.7 * speed,
            ease: 'none',
            onUpdate: () => {
                text.style.clipPath = `polygon(0 0, ${clip.x}% 0, ${clip.x}% 100%, 0 100%)`;
                text.style.opacity = 1;
            }
        }, startTime + 0.1 * speed + 1.3 * speed + 0.8 * speed);
    }

    initPostit() {
        const parent = document.querySelector('.postit');
        if (parent) {
            const dom = {
                container: parent.querySelector('.postit__items'),
                items: [...parent.querySelectorAll('.postit__item')],
            };
            const finalText = this.createAnimateTitle(parent.querySelector('.postit__final__text'), false);
            const tl = new gsap.timeline();
            let isMobile = window.innerWidth < 1024;
            function resize() {
                isMobile = window.innerWidth < 1024;
                const itemHeight = isMobile ? dom.container.clientHeight / 4 : dom.container.clientHeight / 3;
                const containerWidth = isMobile ? itemHeight * 3 : itemHeight * 4;
                const parentWidth = dom.container.parentElement.clientWidth;
                dom.container.style.width = `${containerWidth}px`;
                dom.container.style.right = isMobile ? `${(parentWidth - containerWidth) / 2}px` : `0px`;
                let x = 0;
                let y = 0;
                const postitsPerRow = isMobile ? 3 : 4;
                [...dom.items].forEach((item) => {
                    const shift = isMobile ? 0 : containerWidth - ((containerWidth / 2) + (itemHeight * 2));
                    const translateX = isMobile ? `-${shift + x * itemHeight}px` : `-${x * 100}%`;
                    const translateY = `${y * 100}%`;
                    item.style.transform = `translate(${translateX}, ${translateY})`;
                    x++;
                    if (x >= postitsPerRow) {
                        y++;
                        x = 0;
                    }
                });
            }
            resize();
            window.addEventListener('resize', resize);

            // container zoom to start
            tl.set(dom.container, {
                translateX: isMobile ? '100%' : '75%',
                translateY: isMobile ? '125%' : '66.66%',
                scale: isMobile ? 3 : 2
            });

            this.postitReveal(dom.items[2], tl, 1, 1);

            tl.fromTo(parent.querySelector('.postit__text__0'), {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.8
            }, 3);

            tl.to(parent.querySelector('.postit__text__0'), {
                opacity: 0,
                duration: 0.8
            }, 6);

            tl.fromTo(parent.querySelector('.postit__text__1'), {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.8
            }, 7);

            this.postitReveal(dom.items[1], tl, 8, 1);

            tl.to(parent.querySelector('.postit__text__1'), {
                opacity: 0,
                duration: 0.8
            }, 10);

            tl.fromTo(parent.querySelector('.postit__text__2'), {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.8
            }, 11);

            tl.to(parent.querySelector('.postit__text__2'), {
                opacity: 0,
                duration: 0.8
            }, 14);

            tl.to(dom.container, {
                translateX: isMobile ? '0' : '25%',
                duration: 5,
                ease: 'power2.inOut'
            }, 5);
            tl.to(dom.container, {
                translateX: '0%',
                duration: 5,
                ease: 'power2.inOut'
            }, 10);
            tl.to(dom.container, {
                translateY: '0%',
                scale: 1,
                duration: 6,
                ease: 'power2.inOut'
            }, 10);

            this.postitReveal(dom.items[0], tl, 13, .5);
            this.postitReveal(dom.items[3], tl, 12, .5);
            this.postitReveal(dom.items[4], tl, 15.5, 1);
            this.postitReveal(dom.items[5], tl, 15, .7);
            this.postitReveal(dom.items[6], tl, 14.5, .5);
            this.postitReveal(dom.items[7], tl, 14, .5);
            this.postitReveal(dom.items[8], tl, 11, .5);
            this.postitReveal(dom.items[9], tl, 11, .5);
            this.postitReveal(dom.items[10], tl, 11, .5);
            this.postitReveal(dom.items[11], tl, 11, .5);

            tl.fromTo(parent.querySelector('.postit__final'), {
                opacity: 0
            }, {
                opacity: 1,
                duration: 2,
                onComplete: () => {
                    finalText.play();
                }
            }, 15);
        }
    }

    initModal() {
        const dom = document.querySelector('.modal');
        this.modal.dom = dom;
        if (this.modal.dom) {
            this.modal.window = dom.querySelector('.modal__window');
            this.modal.content = dom.querySelector('.modal__content');
            this.modal.close = dom.querySelectorAll('.modal__close');
            this.modal.swiper = {
                gallery: {
                    dom: dom.querySelector('[data-modal-swiper="gallery"]'),
                    wrapper: null,
                    swiper: null
                },
                thumbnail: {
                    dom: dom.querySelector('[data-modal-swiper="thumbnail"]'),
                    wrapper: null,
                    swiper: null
                }
            };
            this.modal.lenis = new Lenis({
                wrapper: this.modal.window,
                content: this.modal.content,
                lerp: 0.1,
                smoothTouch: true
            });
            this.modal.raf = (time) => {
                this.modal.lenis.raf(time);
                requestAnimationFrame(this.modal.raf);
            }
            if (this.modal.swiper.gallery.dom) {
                this.modal.swiper.thumbnail.swiper = new Swiper(this.modal.swiper.thumbnail.dom, {
                    on: {
                        init: () => {
                            this.modal.swiper.thumbnail.wrapper = this.modal.swiper.thumbnail.dom.querySelector('.swiper-wrapper');
                        },
                        click: (s, evt) => {
                            if (s.clickedIndex >= 0) {
                                this.modal.swiper.gallery.swiper.slideTo(s.clickedIndex);
                            }
                        }
                    },
                    slidesPerView: 4,
                    spaceBetween: 20
                });
                this.modal.swiper.gallery.swiper = new Swiper(this.modal.swiper.gallery.dom, {
                    on: {
                        init: () => {
                            this.modal.swiper.gallery.wrapper = this.modal.swiper.gallery.dom.querySelector('.swiper-wrapper');
                        },
                        slideChange: (s) => {
                            this.modal.swiper.thumbnail.swiper.slideTo(s.activeIndex);
                            [...this.modal.swiper.thumbnail.wrapper.children].forEach((el, i) => {
                                if (i === s.activeIndex) {
                                    el.classList.add('swiper-slide-gallery-active');
                                } else {
                                    el.classList.remove('swiper-slide-gallery-active');
                                }
                            });
                        }
                    },
                    slidesPerView: 1,
                    spaceBetween: 20
                });
            }
            [...dom.querySelectorAll('[data-modal-layout]')].forEach((el) => {
                const key = el.dataset.modalLayout;
                this.modal.layouts[key] = {
                    visible: false,
                    dom: el,
                    items: el.querySelectorAll('[data-modal-item]'),
                    videoContainer: el.querySelector('[data-modal-video]'),
                    images: el.querySelectorAll('[data-modal-image]'),
                    swiper: null
                };
            });
            // events
            [...this.modal.close].forEach(el => {
                el.addEventListener('click', () => {
                    this.closeModal();
                });
            });
            requestAnimationFrame(this.modal.raf);
        }
        [...document.querySelectorAll('[data-modal]')].forEach(el => {
           el.addEventListener('click', (e) => {
               e.preventDefault();
               this.openModal(el);
           })
        });
    }

    resetModal() {
        const modal = document.querySelector('.modal');
        modal.classList.remove('modal--bordeux');
        modal.classList.remove('modal--royal');
        modal.classList.remove('modal--white');
        modal.classList.remove('modal--black');
        const win = modal.querySelector('.modal__window');
        [...modal.querySelectorAll('[data-modal-item]')].forEach(item => {
            item.innerHTML = '';
            if (item.dataset.modalItem === 'video' || item.dataset.modalItem === 'video2') {
                item.parentElement.classList.add('hidden');
                item.parentElement.classList.add('col-span-1');
                item.parentElement.classList.remove('col-span-2');
            }
            if (item.dataset.modalItem === 'url') {
                item.setAttribute('href', '#');
            }
        });
        [...modal.querySelectorAll('[data-modal-image]')].forEach(item => {
            item.innerHTML = '';
        });
        if (this.modal.swiper.gallery.swiper) {
            this.modal.swiper.gallery.wrapper.innerHTML = '';
            this.modal.swiper.thumbnail.wrapper.innerHTML = '';
            this.modal.swiper.gallery.swiper.update();
            this.modal.swiper.thumbnail.swiper.update();
        }
        [...modal.querySelectorAll('[data-modal-layout]')].forEach(layout => {
            layout.classList.add('hidden');
        });
        this.modal.close[this.modal.close.length - 1].classList.remove('hidden');
    }

    closeModal() {
        this.modal.dom.classList.remove('open');
        document.body.classList.remove('no-scroll');
        this.lenis.start();
        setTimeout(() => {
            this.resetModal();
        }, 400);
    }

    setModalVideo(dom, data, singleVideo) {
        if (data.type === 'asset') {
            const video = document.createElement('video');
            video.src = data.path;
            video.classList.add('object-cover');
            video.classList.add('w-full');
            video.classList.add('h-full');
            video.controls = true;
            dom.appendChild(video);
        } else {
            const iframe = document.createElement('iframe');
            iframe.src = `https://www.youtube-nocookie.com/embed/${data.path}`;
            iframe.width = '100%';
            iframe.height = '100%';
            dom.appendChild(iframe);
        }
        dom.parentElement.classList.remove('hidden');
    }

    setContentModal(data) {
        // display layout
        Object.keys(this.modal.layouts).forEach(key => {
            this.modal.layouts[key].dom.classList.add('hidden');
        });
        // set content
        if (data.layout in this.modal.layouts) {
            const layout = this.modal.layouts[data.layout];
            let videoCount = 0;
            let firstVideoContainer = null;
            [...layout.items].forEach(item => {
                const itemKey = item.dataset.modalItem;
                if (itemKey && itemKey in data.content) {
                    const value = data.content[itemKey];
                    if (itemKey === 'video' || itemKey === 'video2') {
                        if (value.type) {
                            videoCount++;
                            if (videoCount === 1) {
                                firstVideoContainer = item;
                            }
                            // make video container visible
                            if (layout.videoContainer) {
                                layout.videoContainer.classList.remove('hidden');
                            }
                            this.setModalVideo(item, value);
                        }
                    } else {
                        item.innerHTML = data.content[itemKey];
                    }
                    if (itemKey === 'url' && data.content[itemKey]) {
                        let url = data.content[itemKey];
                        if (url.indexOf('http') !== 0) {
                            url = `https://${url}`;
                        }
                        item.setAttribute('href', url);
                        item.innerHTML = url.replace('https://', '');
                    }
                }
            });
            [...layout.images].forEach(item => {
                const itemKey = item.dataset.modalImage;
                if (itemKey && itemKey in data.content) {
                    const image = document.createElement('img');
                    image.src = data.content[itemKey];
                    item.appendChild(image);
                }
            });
            // video
            if (videoCount === 1) {
                firstVideoContainer.parentElement.classList.add('col-span-2');
                firstVideoContainer.parentElement.classList.remove('col-span-1');
                layout.videoContainer.classList.add('md:container-sm');
            }
            // gallery
            if (data.content.gallery && data.content.gallery.length) {
                data.content.gallery.forEach((item, i) => {
                    ['thumbnail', 'gallery'].forEach((type) => {
                        const el = document.createElement('div');
                        el.classList.add('swiper-slide');
                        if (i === 0) {
                            el.classList.add('swiper-slide-gallery-active');
                        }
                        const imgContainer = document.createElement('div');
                        imgContainer.classList.add('modal-gallery-image');
                        const image = document.createElement('img');
                        image.src = type === 'gallery' ? item.path : item.thumbnail;
                        imgContainer.appendChild(image);
                        el.appendChild(imgContainer);
                        this.modal.swiper[type].wrapper.appendChild(el);
                    });
                });
                this.modal.swiper.thumbnail.swiper.update();
                this.modal.swiper.gallery.swiper.update();
            }
            layout.dom.classList.remove('hidden');
        }
    }

    // modal window
    // the dom element is the element that has been clicked. it should have the necessary informations as data-attributes
    openModal(dom) {
        // first, reset the content of the modal
        // this.resetModal();
        // api query now
        const apiPath = typeof dom === 'string' ? dom : dom.dataset.modal;
        axios.get(apiPath, {
            headers: {
                locale: document.documentElement.lang
            }
        }).then((response) =>  {
            if (response.data && response.data.layout) {
                // set modal window layout
                const isDomElement = typeof dom !== 'string';
                let modalColor = 'white';
                if (isDomElement) {
                    if (dom.dataset.modalColor) {
                        modalColor = dom.dataset.modalColor;
                    }
                }
                this.modal.dom.classList.add(`modal--${modalColor}`);
                document.body.classList.add('no-scroll');
                // hide close on demand (last one is the main close x)
                if (response.data.layout && response.data.layout === 'company') {
                    this.modal.close[this.modal.close.length - 1].classList.add('hidden');
                }
                this.lenis.stop();
                // set content
                this.setContentModal(response.data);
                this.modal.dom.classList.add('open');
            }
        });
    }
    createAnimateTitle(el, autoplay = false) {
        const text = new Divido(el, { type: 'char' });
        const parent = el.parentElement;
        const allWaitingAfter = parent.querySelectorAll('.after-animate-title');

        const from = {
            'will-change': 'opacity, transform',
            opacity: 0,
            yPercent: 0,
            scaleY: 0.5,
            scaleX: 0.5,
            transformOrigin: '50% 50%'
        };
        const to = {
            duration: .7,
            ease: 'cubic.inOut(2)',
            opacity: 1,
            yPercent: 0,
            scaleY: 1,
            scaleX: 1,
            stagger: 0.03,
            onStart: () => {
                el.style.opacity = 1;
            },
            onComplete: () => {
                // console.log('onComplete', text.char.length);
                [...allWaitingAfter].forEach(after => {
                    after.classList.add('view');
                });
            }
        };

        if (el.classList.contains('animate-scroll')) {
            to.scrollTrigger = {
                trigger: text.selector,
                start: 'center top+=95%',
                end: 'bottom top+=50%',
                scrub: true
            };
        } else {
            to.paused = true;
        }
        const animation = gsap.fromTo(text.char, from, to);
        if (to.paused && autoplay) {
            this.addScrollTriggerElement(el, () => {
                animation.play();
            });
        }
        el.classList.remove('opacity-0');
        return animation;
    }
};

/* special js animations */
class SWEffects extends SWScroll {
    constructor() {
        super();
        const main = document.querySelector('main');
        this.initMenu();
        this.initDocument();
    }

    // Headline fade-in effect
    fadeInHeadline(el) {
        const headlines = el.target.querySelectorAll('.text-animation');
        if (!headlines.length) {
            return;
        }
        const headline = headlines[0];
        headline.classList.remove('view');
        // if not already saved, save the original text (else SplitText would replicate the content on a new trigger)
        if (!headline.dataset.animationtext) {
            headline.dataset.animationtext = headline.innerHTML;
        }
        // each word in a span
        const words = headline.dataset.animationtext.split(' ');
        let wordsOutput = '';
        words.forEach(word => {
            wordsOutput += '<span class="text-animation__word">' + word + ' </span>';
        });
        headline.innerHTML = wordsOutput;
        const linesByOffset = {};
        const lines = {};
        let lineNumber = 0;
        [...headline.querySelectorAll('.text-animation__word')].forEach(word => {
            const top = word.offsetTop;
            if (!(top in linesByOffset)) {
                lineNumber++;
                linesByOffset[top] = '';
                lines[lineNumber] = '';
            }
            lines[lineNumber] += word.innerHTML;
        });
        let htmlOutput = '';
        Object.keys(lines).forEach((line) => {
            htmlOutput += '<div class="text-animation__line"><div class="text-animation__line__inner">' + lines[line] + '</div></div>';
        });
        headline.innerHTML = htmlOutput;
        setTimeout(() => {
            headline.classList.add('view');
        }, 100);
    }

    // number counter
    counter(el, startNumber = 0, endNumber = 0, duration = 2000, delay = 0, easingFunction = 'easeInOutCubic') {
        if (el.target.dataset.counterActive) {
        } else {
            if (startNumber !== endNumber) {
                easingFunction = easingFunction in this.easing ? easingFunction : 'easeInOutCubic';
                el.target.dataset.counterActive = true;
                const numberFormat = new Intl.NumberFormat('de-DE', {style: 'decimal'});
                el.target.innerHTML = numberFormat.format(startNumber);
                let startDate;
                const tick = function () {
                    const now = new Date();
                    const delta = now - startDate;
                    let ease = this.easing[easingFunction](delta / duration);
                    if (delta > duration) {
                        cancelAnimationFrame(tick);
                        ease = 1;
                        delete el.target.dataset.counterActive;
                    } else {
                        requestAnimationFrame(tick);
                    }
                    const value = Math.floor(endNumber * ease);
                    el.target.innerHTML = numberFormat.format(value);
                }.bind(this);
                setTimeout(function () {
                    startDate = new Date();
                    requestAnimationFrame(tick);
                }.bind(this), delay);
            }
        }
    }

    intersect(element) {
        setTimeout(function () {
            element.target.classList.add('view');
            if (element.target.dataset.view) {
                element.target.classList.add(element.target.dataset.view);
            }
            if (element.target.dataset.viewjs) {
                try {
                    eval(element.target.dataset.viewjs);
                } catch (e) {
                    console.log(e);
                }
            }
            if (element.animation) {
                element.animation.play();
            }
        }.bind(this), 30);
    }

    embed(dom) {
        const regex = /\[\/?(?:embed){1,}.*?]/mgi;
        let html = dom.innerHTML;
        const embeds = regex.exec(html);
        if (embeds && embeds.length) {
            embeds.forEach((found) => {
                if (found.indexOf('=') > -1) {
                    let url = found.substr(found.indexOf('=') + 1);
                    url = url.substr(0, url.indexOf(']')).trim();
                    const newHtml = `<div class="vue-app"><embed-social url="${url}"></embed-social></div>`;
                    html = html.replace(found, newHtml);
                }
            });
            dom.innerHTML = html;
        }
    }

    initMenu() {
        const header = document.querySelector('header');
        if(!header) return;
        this.menu = {
            header: header,
            burger: header.querySelector('.burger'),
            content: document.querySelector('nav'),
            menuContent: document.querySelector('.menu__content'),
            closeArea: document.querySelector('.menu__closearea'),
            title: {
                dom: header.querySelector('.websitetitle'),
                lastScrollY: 0,
                visible: true
            },
            open: false,
            toggle: () => {
                if (this.menu.open) {
                    this.menu.open = false;
                    this.lenis.start();
                    // this.menu.lenis.stop();
                    // this.menu.title.dom.classList.remove('open');
                    this.menu.header.classList.remove('open');
                    this.menu.content.classList.remove('open');
                    this.menu.burger.classList.remove('open');
                } else {
                    this.menu.open = true;
                    this.lenis.stop();
                    // this.menu.lenis.start();
                    // this.menu.title.dom.classList.add('open');
                    this.menu.header.classList.add('open');
                    this.menu.content.classList.add('open');
                    this.menu.burger.classList.add('open');
                }
            }
        };

        const topGradient = document.querySelector('.menu__content--top');
        const bottomGradient = document.querySelector('.menu__content--bottom');
        const scrollContent = document.querySelector('.menu__scroll');
        this.menu.lenis = new Lenis({
            wrapper: this.menu.menuContent,
            content: scrollContent,
            lerp: 0.1,
            smoothTouch: true
        });
        const raf = (time) => {
            this.menu.lenis.raf(time);
            requestAnimationFrame(raf);
        };
        let topVisible = false;
        let bottomVisible = true;
        this.menu.lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
            // console.log({ scroll, limit, velocity, direction, progress });
            // on scroll set var and call all registered functions
            if (scroll > 20) {
                if (!topVisible) {
                    topVisible = true;
                    topGradient.classList.remove('hide');
                }
                const height = scrollContent.clientHeight;
                const wrapperHeight = this.menu.menuContent.clientHeight;
                if (scroll + wrapperHeight >= height - 40) {
                    if (bottomVisible) {
                        bottomVisible = false;
                        bottomGradient.classList.add('hide');
                    }
                } else {
                    if (!bottomVisible) {
                        bottomVisible = true;
                        bottomGradient.classList.remove('hide');
                    }
                }
            } else {
                if (topVisible) {
                    topVisible = false;
                    topGradient.classList.add('hide');
                }
            }
        });
        requestAnimationFrame(raf);
        // this.menu.lenis.stop();

        if (this.menu.burger) {
            this.menu.burger.addEventListener('click', (e) => {
                e.preventDefault();
                this.menu.toggle();
            });
        }
        if (this.menu.closeArea) {
            this.menu.closeArea.addEventListener('click', (e) => {
                e.preventDefault();
                this.menu.toggle();
            });
        }
        if (this.menu.content) {
            document.body.addEventListener('keyup', (e) => {
                // console.log(e.code);
                if (this.menu.open && e.code === 'Escape') {
                    this.menu.toggle();
                }
            });
            // page title
            this.scrollFunctions.push(() => {
                if (this.winWidth < 767) {
                    if (this.menu.title.visible) {
                        if (this.scrollTop > 50) {
                            this.menu.title.visible = false;
                            this.menu.title.dom.classList.remove('view');
                        }
                    } else {
                        if (this.scrollTop <= 50) {
                            this.menu.title.visible = true;
                            this.menu.title.dom.classList.add('view');
                        }
                    }
                } else {
                    if (this.menu.title.visible) {
                        if (this.scrollTop > this.menu.title.lastScrollY) {
                            this.menu.title.visible = false;
                            this.menu.title.dom.classList.remove('view');
                        }
                    } else {
                        if (this.scrollTop < this.menu.title.lastScrollY) {
                            this.menu.title.visible = true;
                            this.menu.title.dom.classList.add('view');
                        }
                    }
                }
                this.menu.title.lastScrollY = this.scrollTop * 1;
            });
        }
    }

    initDocument() {
        const isBackend = document.querySelector('main.backendmode');
        [...document.querySelectorAll('[class*=\'view:\'],[class*=\'fade-in\'],[class*=\'group-observe\']')].forEach((el) => {
            // get parent
            const isInSlider = el.closest('.swiper-slide') ? true : false;
            if (isInSlider) {
                // console.log(el, 'add class');
                el.classList.add('view');
            } else {
                if ((el.classList.contains('fade-in') || el.classList.contains('group-observe') || el.className.indexOf('view:') > -1) && !el.dataset.observed) {
                    el.dataset.observed = 'true';
                    // console.log('observe', el);
                    this.addScrollObserver(el);
                }
            }
        });

        // // search replace embed code
        [...document.querySelectorAll('.textcontent')].forEach((dom) => {
            this.embed(dom);
        });
        // // hide embed code in news overview
        [...document.querySelectorAll('.article p')].forEach((dom) => {
            const regex = /\[\/?(?:embed){1,}.*?]/mgi;
            const found = regex.exec(dom);
            if (found.length) {
                found.forEach((found) => {
                    // console.log('remove p', found.innerHTML);
                    found.remove();
                });
            }
        });

        // accordion
        [...document.querySelectorAll('.accordion')].forEach((dom) => {
            const elements = dom.querySelectorAll('.accordion__element');
            [...elements].forEach((element) => {
                let isOpen = element.classList.contains('open');
                const title = element.querySelector('.accordion__title');
                title.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (isOpen) {
                        element.classList.remove('open');
                        isOpen = false;
                    } else {
                        element.classList.add('open');
                        isOpen = true;
                    }
                });
            });
        });

        [...document.querySelectorAll('.sidescroll')].forEach((dom) => {
            this.sidescrollElements.push({
                parent: dom,
                flex: dom.querySelector('.flex')
            });
        });

        const interactiveReveal = document.getElementById('interactiveReveal');
        if (interactiveReveal) {
            const ir = new SwInteractiveReveal(interactiveReveal);
            if (ir.mobileImage) {
                this.addOnScroll(() => {
                    const bounding = ir.mobileImage.getBoundingClientRect();
                    // if a scrollfit element
                    if (this.isInViewport(bounding)) {
                        const screenHalf = (this.winHeight / 2) + 40;
                        const progress = bounding.top - screenHalf;
                        const opacity = Math.max(0, 1 - (bounding.top < screenHalf ? Math.abs(progress / bounding.height) : 0));
                        ir.mobileImage.style.opacity = Math.max(0, opacity);
                    }
                });
            }
        }

        if (!isBackend) {
            [...document.querySelectorAll('.animate-title')].forEach((el) => {
                this.createAnimateTitle(el, true);
            });

            [...document.querySelectorAll('.scroll-zoom')].forEach(dom => {
                this.addScrollZoomElement(dom);
            });

            [...document.querySelectorAll('.scroll-mirror')].forEach(dom => {
                this.addScrollMirrorElement(dom);
            });
        }

        this.scrollTrigger();
        this.scrollZoom();
        this.scrollMirror();
        this.initPostit();
    }
};

export default SWEffects;
