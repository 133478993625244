export default class SwInteractiveReveal {
    constructor (dom) {
        this.svg = dom;
        this.parent = dom.parentElement;
        this.pathsGroup = document.getElementById('ir-paths');
        this.circle = document.getElementById('ir-debug');

        this.mobileImage = document.getElementById('interactiveRevealMobile');

        this.width = 1920;
        this.height = 1080;
        if (this.parent.clientWidth < 768) {
            this.width = 1080;
        }
        this.expect2current = this.parent.clientWidth / this.width;

        this.pointerdown = false;
        this.currentPath = { x: null, y: null };
        this.currentPathProperties = '';
        // this.svg.addEventListener('pointerdown', (e) => {
        //    this.pointerdown = true;
        //    if (!this.currentPath) {
        //        this.createPath(e);
        //    }
        // });
        // this.svg.addEventListener('pointerup', () => {
        //     this.pointerdown = false;
        //     this.currentPath = null;
        //     this.currentPathProperties = '';
        // });
        let touchstart = false;
        if (this.width >= 768) {
            this.svg.addEventListener('touchstart', (e) => {
                touchstart = true;
            });
            this.svg.addEventListener('touchend', (e) => {
                touchstart = false;
            });
            this.svg.addEventListener('touchmove', (e) => {
                const { x, y } = this.getXY(e.touches[0].clientX, e.touches[0].clientY);
                this.createCircle(x, y);
                // add circle between
                if (this.currentPath.x !== null) {
                    this.createCircle((x + this.currentPath.x) / 2, (y + this.currentPath.y) / 2);
                }
                this.currentPath.x = x;
                this.currentPath.y = y;
            });
            this.svg.addEventListener('mousemove', (e) => {
                e.stopPropagation();
                e.preventDefault();
                const { x, y } = this.getXY(e.clientX, e.clientY);
                this.createCircle(x, y);
                // add circle between
                if (this.currentPath.x !== null) {
                    this.createCircle((x + this.currentPath.x) / 2, (y + this.currentPath.y) / 2);
                }
                this.currentPath.x = x;
                this.currentPath.y = y;
                this.draw(x, y);
                // gsap.fromTo(path,
                // {
                //     r: 80,
                //     fillOpacity: 1
                // }, {
                //     attr: {
                //         r: 60,
                //         fillOpacity: 0
                //     },
                //     duration: 1000,
                //     delay: 3000,
                //     ease: "none",
                //     onStart: () => {
                //         console.log('start', path);
                //     },
                //     onComplete: () => {
                //         path.remove();
                //     }
                // });
            });
        }
    }

    createCircle(x, y) {
        const currentPath = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        currentPath.setAttribute('cx', `${x}`);
        currentPath.setAttribute('cy', `${y}`);
        currentPath.setAttribute('r', '80');
        currentPath.setAttribute('fill', 'url(#ir-circle-gradient)');
        currentPath.setAttribute('fill-opacity', '1');
        this.pathsGroup.appendChild(currentPath);
        currentPath.classList.add('ir-circle-fadeout');
        // gsap.to(currentPath, {
        //     attr: {
        //         'fill-opacity': 0
        //     },
        //     duration: 1,
        //     delay: 3,
        //     onComplete: () => {
        //         currentPath.remove();
        //     }
        // });
        setTimeout(() => {
            // currentPath.remove();
        }, 3000);
    }

    createPath(evt) {
        const { x, y } = this.getXY(evt);
        const currentPath = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
        currentPath.classList.add('ir-line');
        this.setPathXY(x, y, currentPath);
        this.pathsGroup.appendChild(currentPath);
        return currentPath;
    }

    getXY(clientX, clientY) {
        const r = this.svg.getBoundingClientRect();
        const x = Math.round(((clientX - r.left) / this.expect2current) * 10) / 10;
        const y = Math.round(((clientY - r.top) / this.expect2current) * 10) / 10;
        return { x: x, y: y };
    }

    setPathXY(x, y, currentPath) {
        if (currentPath) {
            this.currentPathProperties = `${this.currentPathProperties} ${x},${y}`;
            currentPath.setAttribute('points', this.currentPathProperties);
        }
    }

    draw (x, y) {
        this.circle.setAttribute('cx', x);
        this.circle.setAttribute('cy', y);
        if (this.pointerdown) {
            this.setPathXY(x, y);
        }
    }
}
