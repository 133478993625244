import '../scss/app.scss';

import Intersect from './intersect'; // IntersectionObserver
import SWEffects from './swScroll'; // Scroll Trigger Library, automatic fade in
import SwiperOnPage from './swiper'; // global swiper setup

/*
this is the global IntersectionObserver
 */
if (!('roObserver' in window)) {
    // @ts-ignore
    window.roObserver = new Intersect({});
}

document.addEventListener('DOMContentLoaded', function (event) {
    console.log('%cMade by SEITWERK', 'background: #222; color: #bada55');
    window.SWScroll = new SWEffects();
    const swiper = new SwiperOnPage();
    swiper.init();

    [...document.querySelectorAll('.mainmenu a.main')].forEach((dom) => {
       dom.href = '#';
       dom.addEventListener('click', (e) => {
           e.preventDefault();
       });
    });

    [...document.querySelectorAll('video')].forEach((video) => {
        // image-text timeline update
        let interval;
        let text = [];

        // initial set correct source according to window size
        if (video.dataset.srcMobile) {
            if (window.SWScroll.winWidth < 768) {
                video.src = video.dataset.srcMobile;
            }
            window.addEventListener('resize', () => {
                console.log(window.innerWidth, video.src, video.dataset.srcMobile);
                if (window.innerWidth < 768) {
                    if (video.dataset.srcCurrent !== video.dataset.srcMobile) {
                        video.dataset.srcCurrent = video.dataset.srcMobile;
                        video.src = video.dataset.srcMobile;
                        video.currentTime = 0;
                    }
                } else {
                    if (video.dataset.srcCurrent !== video.dataset.src) {
                        video.dataset.srcCurrent = video.dataset.src;
                        video.src = video.dataset.src;
                        video.currentTime = 0;
                    }
                }
            });
        }

        const hasImageText = video.dataset.text;
        if (hasImageText) {
            const parent = video.parentElement.parentElement; // div.video-timeline__content
            [...parent.querySelectorAll('.video-timeline__text')].forEach((textDom) => {
                const dataset = JSON.parse(textDom.dataset.videotext);
                text.push({
                    dom: textDom,
                    visible: false,
                    hidden: false,
                    start: dataset.start,
                    end: dataset.end,
                    animation: textDom.classList.contains('text--animate') ? window.SWScroll.createAnimateTitle(textDom.querySelector('.video-timeline__animate'), false) : null
                });
            });
        }
        const parent = video.parentElement;
        video.addEventListener('play', (e) => {
            if (hasImageText) {
                interval = setInterval(() => {
                    text.forEach((t) => {
                        if (t.start) {
                            if (t.start <= video.currentTime && !t.visible) {
                                t.visible = true;
                                t.dom.classList.add('text--visible');
                                if (t.animation) {
                                    t.animation.play(0);
                                }
                                // console.log('set text visible', 139, video.currentTime);
                            }
                            if (t.visible && t.start > video.currentTime) {
                                t.visible = false;
                                t.dom.classList.remove('text--visible');
                                // console.log('remove text visible', 144, video.currentTime);
                            }
                        }
                        if (t.end) {
                            if (t.end === -1) { t.end = video.duration; }
                            if (video.currentTime >= t.end && !t.hidden) {
                                t.hidden = true;
                                t.dom.classList.add('text--hidden');
                                // console.log('set text hidden', 152, video.currentTime);
                            }
                            if (video.currentTime < t.end && t.hidden) {
                                t.hidden = false;
                                t.dom.classList.remove('text--hidden');
                                // console.log('remove text hidden', 157, video.currentTime);
                            }
                        }
                        // console.log(video.currentTime, t.start, t.end, t.visible, t.hidden);
                    });
                }, 10);
            }
        });
        video.addEventListener('pause', (e) => {
            if (hasImageText) {
                clearInterval(interval);
            }
        });
        video.addEventListener('ended', (e) => {
            if (!video.loop) {
                video.dataset.endedNative = 'true';
            }
        });
        if (video.dataset.autoplay) {
            const loop = video.loop;
            video.dataset.outofbounds = 'true';
            const checkAutoplay = () => {
                const playing = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
                let shouldPlay = false;
                const bounds = video.getBoundingClientRect();
                let isOutOfBounds = bounds.y + bounds.height <= 0 || bounds.y > window.SWScroll.height;
                let endedNative = video.dataset.endedNative && video.dataset.endedNative !== '';

                if (!isOutOfBounds) {
                    if (video.dataset.autoplay === 'viewport' && bounds.y < window.SWScroll.winHeight && bounds.y > window.SWScroll.winHeight * -1) {
                        shouldPlay = true;
                    } else if (video.dataset.autoplay === 'center' && bounds.y <= window.SWScroll.winHeight / 2 && bounds.y > window.SWScroll.winHeight * -1) {
                        shouldPlay = true;
                    } else if (video.dataset.autoplay === 'center' && bounds.y <= 0 && bounds.y > window.SWScroll.winHeight * -1) {
                        shouldPlay = true;
                    } else if (video.dataset.autoplay === 'top' && bounds.y < window.SWScroll.winHeight * .1 && bounds.y > window.SWScroll.winHeight * -1) {
                        shouldPlay = true;
                    }
                } else {
                    if (endedNative) {
                        video.dataset.endedNative = '';
                        endedNative = '';
                    }
                }
                if (shouldPlay && !loop && !playing && !video.dataset.outOfBounds && endedNative) {
                    shouldPlay = false;
                    // console.log('no playing');
                }
                if (shouldPlay && !playing) {
                    // console.log('endedNative', video.dataset.endedNative, video.dataset.outOfBounds, loop);
                    video.play();
                    video.dataset.outOfBounds = '';
                    video.dataset.endedNative = '';
                }
                if (!shouldPlay && playing) {
                    video.pause();
                    if (isOutOfBounds) {
                        video.dataset.outofbounds = 'true';
                    }
                }
            };
            window.SWScroll.addOnScroll(checkAutoplay);
            video.addEventListener('canplay', checkAutoplay);
        }
    });
});
