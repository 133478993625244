import Swiper from 'swiper';

export default class SwiperOnPage {
    swiperSettings = {
        'home-stage-swiper': {
            slidesPerView: 'auto',
            spaceBetween: 20,
            breakpoints: {
                768: {
                    slidesPerView: 3
                }
            }
        },
        'news-slider': {
            spaceBetween: 20,
            pagination: {
                el: ".customer-pagination",
                clickable: true,
            },
            breakpoints: {
                '320': {
                    'slidesPerView': 'auto'
                },
                '1330': {
                    'slidesPerView': 3
                }
            }
        },
        'card-slider': {
            spaceBetween: 20,
            slidesPerView: 'auto',
            pagination: {
                el: ".customer-pagination",
                clickable: true,
            }
        },
        'gallery-slider': {
            spaceBetween: 20,
            slidesPerView: 'auto',
            pagination: {
                el: ".customer-pagination",
                clickable: true,
            }
        },
        'artgallery-slider': {
            spaceBetween: 170,
            slidesPerView: 'auto',
            pagination: {
                el: ".customer-pagination",
                clickable: true,
            }
        }
    };

    constructor() {}

    init() {
        Object.keys(this.swiperSettings).forEach(className => {
            const all = document.querySelectorAll(`.${className}`);
            all.forEach(dom => {
                const settings = Object.assign({}, this.swiperSettings[className]);
                // replace breakpoints by dataset
                if (dom.dataset && dom.dataset.breakpoints) {
                    settings.breakpoints = JSON.parse(dom.dataset.breakpoints);
                }
                const next = dom.querySelector('.swiper-button-next');
                const prev = dom.querySelector('.swiper-button-prev');
                if (prev && next) {
                    settings.navigation = {
                        nextEl: next,
                        prevEl: prev,
                        enabled: true
                    };
                    next.addEventListener('click', (e) => {
                        console.log('next click');
                    })
                }
                if (className === 'artgallery-slider') {
                    const overlay = dom.parentElement.parentElement.querySelector('.gallery__overlay');
                    settings.on = {
                        init: (_swiper) => {
                            this.setBackground(_swiper, overlay);
                            if (next) {
                                next.addEventListener('click', (e) => {
                                    _swiper.slideNext();
                                });
                                prev.addEventListener('click', (e) => {
                                    _swiper.slidePrev();
                                });
                            }
                        },
                        slideChange: (_swiper) => {
                            this.setBackground(_swiper, overlay);
                        }
                    };
                }
                const swiper = new Swiper(dom, settings);
            });
        });
    }

    setBackground(swiper, overlay) {
        if (overlay) {
            overlay.style.backgroundImage = window.innerWidth < 460 && swiper.slides[swiper.activeIndex].dataset.galleryImageMobile ? `url(${swiper.slides[swiper.activeIndex].dataset.galleryImageMobile})` : `url(${swiper.slides[swiper.activeIndex].dataset.galleryImage})`;
        }
    }
}
